/*
 * Downloads for ASSAB
 * -------------------
 * 
 * Sorry for the mess... :/
 * Dependencies: jQuery, isotope.js
 * 
 */

(function($){
	
    var AssabDownloads = {
    	
    	/* Variables, Constants, ... */
		self: null,
		multiSelect: false, // if true, multiple categories can be selected
		isotopeContainer: null,
		isotopeFilters: {'categories' : []},
		autocompleteData: [],
		autocompleteOpen: false,
		filterCategoryPrefix: 'category-',
		scroller: null,
		qsRegex: null,
		        
		initWhenDomIsReady: function() {
			AssabDownloads.isotopeContainer = $('.downloads');
			AssabDownloads.renderCategoryFilter();
			AssabDownloads.insertMainCategoryClearfixes(); // dependent on renderCategoryFilter()
			AssabDownloads.updateCategoryCount();
			if(AssabDownloads.isXs()) AssabDownloads.activateIScroll();
			AssabDownloads.getDownloads();
			AssabDownloads.isotope();
			AssabDownloads.autocomplete();
			AssabDownloads.delegates();
		},
		
		/* All Delegates and EventHandlers */
		delegates: function() {
			// typeahead
			var $quicksearch = $('#downloadsearchinput').on({
				'typeahead:selected': debounce(function() {
					AssabDownloads.executeQuery($quicksearch.val());
				}, 200),
				'keypress': function(event) {
					if(event.which == 13) {
						$(this).typeahead('close');
				        AssabDownloads.executeQuery($quicksearch.val());
				        $(this).blur();
				    }
				},
				'submit': function(event) {
					event.preventDefault();
				}
			});
			$("form.search").on('submit', function(event) {
				$(this).blur();
				event.preventDefault();
			});
			$("#downloadsearchinput").on('typeahead:opened', function() {
				AssabDownloads.autocompleteOpen = true;
			});
			$("#downloadsearchinput").on('typeahead:closed', function() {
				AssabDownloads.autocompleteOpen = false;
			});
			$("#downloadsearchdropdown").on('click', function() {
				if(AssabDownloads.autocompleteOpen) {
					$('#downloadsearchinput').typeahead('close');
				} else {
					$('#downloadsearchinput').typeahead('val', '');
					$('#downloadsearchinput').typeahead('open');					
				}
			});
			
			// use categories to filter
			$('.filter .categories').on('click', 'i.bottom', function(event) {
				$(this).parent().find('button').trigger('click');
			});
			$('.filter .categories').on('click', 'i.top', function(event) {
				var subCategories = $(this).parent().parent().find('.sub-categories a');
				if(subCategories.length > 0) {
					$.each(subCategories, function() {
						$(this).removeClass('is-checked');
					});
					$(this).parent().parent().removeClass('active');
					$(this).parent().find('button').removeClass('active');
				}
				$(this).parent().find('button').trigger('delete');
			});
			$('.filter .categories .sub-categories').on('click delete delete-filter', 'a', function(event) {
				
				event.preventDefault();
            	
				var filterCategory = $(this).parent().parent().parent();
				var button = $(this).parent().parent().parent().find('button');
				if($(this).hasClass('is-checked')) {
            		$(this).removeClass('is-checked');
            		if($(this).parent().find('a.is-checked').length === 0) {
            			$(filterCategory).removeClass('active');
            			$(button).removeClass('active');
            		}
            	} else {
            		if(!$(this).hasClass('disabled')) { // IE8            			
            			var mainCategoryActive = $(button).hasClass('active');
            			if(!mainCategoryActive) {
            				$(filterCategory).addClass('active');
            				$(button).addClass('active');
            			}
            			$(this).parent().find('a.filter-category-main').removeClass('is-checked');
            			$(this).addClass('is-checked');
            		}
            	}
            	
            	if(!AssabDownloads.multiSelect && event.type === 'click') {
            		// unselect all other sub-categories
            		$(this).parent().find('a.is-checked').not(this).trigger('delete');
            	}
            	// select main category, when all subcategories are unchecked via filter
            	if(event.type === 'delete-filter' && $(this).parent().find('a.is-checked').length === 0) {
            		// unselect all other sub-categories
            		$(this).parent().find('a.filter-category-main').not(this).trigger('click');
            	}
            	
            	AssabDownloads.generateFilterArray();
            	AssabDownloads.isotope();
            	AssabDownloads.renderTitle();
			});
            $('.filter .categories').on('click delete collapse', 'button', function(event) {
            	
            	// hide active dropdowns
            	if(event.type === 'click') {
            		$('.filter .categories .sub-categories.active').parent().find('button').not(this).trigger('collapse');
            	}
            	if(!AssabDownloads.multiSelect && event.type === 'click') {
            		// unselect all other buttons
            		$('.filter .categories button.active').not(this).parent().find('i.top').trigger('click');
            		$('.filter .categories button.is-checked').not(this).trigger('delete');
            	}
            	
            	var filterCategory = $(this).parent().parent();
            	var filterCategoryHasChildren = ($(filterCategory).find('.sub-categories').length > 0) ? true : false;
            	
            	if(filterCategoryHasChildren) {
            		var filterCategoryDropdown = $(this).parent().find('i.bottom');
            		if($(filterCategoryDropdown).hasClass('active')) {
						$(filterCategory).find('.sub-categories').removeClass('active');
	            		$(filterCategoryDropdown).removeClass('active');
	            		$(filterCategoryDropdown).removeClass('icon-arrow-66');
	            	} else {
	            		if(event.type != 'delete' && !$(this).hasClass('disabled')) {
	            			$(filterCategory).find('.sub-categories').addClass('active');
	            			$(filterCategoryDropdown).addClass('active');
	            			$(filterCategoryDropdown).addClass('icon-arrow-66');
	            			
	            			var filterPosition = $(filterCategory).position();
	            			var filterPositionOffsetLeft = filterPosition.left;
	            			var filterWidth = $('.download-filter .col-sm-12').width();
	            			var filterItemWidth = $(this).width();
	            			var filterArrowPosition = filterPositionOffsetLeft + (filterItemWidth / 2);
	            			$('<style>.sub-categories-arrow:after{left:' + filterArrowPosition + 'px !important;}</style>').appendTo('head');
	            			$(filterCategory).find('.sub-categories').css({left: '-' + filterPositionOffsetLeft + 'px', width: filterWidth + 'px'});
	            			
	            			// automatically select parent
	            			if($(filterCategory).find('.sub-categories a.is-checked').length === 0) {
	            				$(filterCategory).find('.sub-categories a').first().addClass('is-checked');
			            		$(filterCategory).addClass('active');
		        				$(filterCategory).find('button').addClass('active');
	            			}
	            		}
	            	}
	            	if(AssabDownloads.scroller !== null) {
	            		AssabDownloads.scroller.refresh();
	            	}
            	} else {
            		if($(this).hasClass('is-checked')) {
	            		$(this).removeClass('is-checked');
	            		$(filterCategory).removeClass('active');
	            	} else {
	            		if(!$(this).hasClass('disabled')) { // IE8
	            			$(this).addClass('is-checked');
	            			$(filterCategory).addClass('active');
	            		}
	            	}
            	}
            	AssabDownloads.generateFilterArray();
            	AssabDownloads.isotope();
            	AssabDownloads.renderTitle();
			});
			
			// iScroll
			$(window).on('resize', debounce(function() {
				AssabDownloads.activateIScroll();
				AssabDownloads.insertMainCategoryClearfixes();
			}, 100));
        },
        
        executeQuery: function(value) {
        	var query = value;
			var query = query.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
			AssabDownloads.qsRegex = new RegExp(query, 'gi');
			AssabDownloads.isotopeContainer.isotope();
			AssabDownloads.updateCategoryCount();
			AssabDownloads.scrollToContent();
        },
        
        scrollToContent: function() {
			$('html, body').animate({
				scrollTop: $("#downloadsearchinput").offset().top - 50
			}, 800);
        },
        
        autocomplete: function() {
        	$('#downloadsearchinput').typeahead({
				hint: false,
				highlight: true,
				minLength: 0
			},
			{
				name: 'autocompleteData',
				displayKey: 'value',
				source: substringMatcher(AssabDownloads.autocompleteData)
			});
        },
        
        getDownloads: function() {
        	$(".item").each(function(index, item) {
        		downloadTitle = $(item).find('h4').text();
        		AssabDownloads.autocompleteData.push(downloadTitle);
        	});
        },
        
        renderCategoryFilter: function() {
        	if(typeof downloadFilterData !== 'undefined') {
        		$('div.categories').html(AssabDownloads.getCategoryFilterHtml(downloadFilterData));
        		
        		// categories with only 4 subcategories should be display as two column layout
        		$('div.categories .filter-category').each(function() {
        			var subItems = $(this).find('.sub-categories a').length;
        			if(subItems === 5) {
        				$(this).find('.sub-categories').addClass('twoCol');
        			}
        		});
        	}
        },
        
        activateIScroll: function() {
    		if(AssabDownloads.isXs()) {
    			AssabDownloads.deactivateIScroll();
    			AssabDownloads.scroller = new IScroll('.block-scroll', {
        			scrollbars: 'custom',
					mouseWheel: true,
					interactiveScrollbars: true,
					preventDefaultException: { tagName: /^(INPUT|TEXTAREA|BUTTON|A|SELECT|STRONG|SPAN|I|IMG|P)$/ }
        		});
    		} else {
    			AssabDownloads.deactivateIScroll();
    		}
        },
        
        deactivateIScroll: function() {
        	if(AssabDownloads.scroller !== null) {
    			AssabDownloads.scroller.destroy();
				AssabDownloads.scroller = null;
    		}
        },
        
        isXs: function() {
        	return (ResponsiveHelper.getSize() === 'xs') ? true : false;
        },
        
        isSm: function() {
        	return (ResponsiveHelper.getSize() === 'sm') ? true : false;
        },
        
        insertMainCategoryClearfixes: function() {
        	$('.download-filter .categories .clearfix').remove();
        	var breakAfter = AssabDownloads.isSm() ? 2 : 3;
        	if(!AssabDownloads.isXs()) {
        		$('.download-filter .categories .filter-category').each(function(key, value) {
	        		if((key + 1) % breakAfter == 0) {
	        			$(this).after($('<div/>').addClass('clearfix'));
	        			$(this).addClass('aaaaaahh');
	        		}
	        	});
        	}
        },
        
        getCategoryFilterHtml: function(data) {
        	var source   = $("#template-category-filter").html();
			var template = Handlebars.compile(source);
			var context  = data;
        	return template(context);
        },
        
        updateCategoryCount: function() {
        	$(".categories button").each(function(index, value) {
        		var categoryValue = $(value).data('filter-category').toString();
        		var itemCount = AssabDownloads.getCategoryCount(this, categoryValue);
        		var isChecked = $(this).hasClass('is-checked');
        		$(this).find('span').text(itemCount);
        		if(isIE(9,'lte')) { // IE8
        			if(itemCount == 0 && !isChecked) {
	        			$(this).addClass('disabled');
	        			$(this).attr("onclick", "javascript:return false;");
	        		} else {
	        			$(this).removeClass('disabled');
	        			$(this).removeAttr("onclick");
	        		}
				} else {
					if(itemCount == 0 && !isChecked) {
	        			$(this).attr('disabled', 'disabled');
	        		} else {
	        			$(this).removeAttr('disabled');
	        		}
				}
        	});
        },
        
        getCategoryCount: function(category, categoryValue) {
        	// get categories
        	var count = 0;
        	
    		$.each($('.item').not(".not-in-search"), function() {
    			if(AssabDownloads.itemHasCategory(this, categoryValue)) {
    				count++;
    			}
    		});
        	
        	if($(category).parent().parent().find('.sub-categories').length >= 0) {
				// get itemcount from sub-categories
        	}
        	
        	return count;
        },
        
        itemHasCategory: function(item, categoryValue) {
        	var hasCategory = false;
        	var categories = $(item).data('category').toString().split(',');
        	$.each(categories, function(index, value) {
        		if(value == categoryValue) {
        			hasCategory = true;
        			return false;
        		}
        	});
        	return hasCategory;
        },
        
        generateFilterArray: function() {
        	AssabDownloads.isotopeFilters['categories'] = [];
        	$('.filter .categories button.is-checked, .filter .categories a.is-checked').each(function(index, element) {
        		AssabDownloads.isotopeFilters['categories'].push(AssabDownloads.filterCategoryPrefix + $(element).attr('data-filter-category'));
        	});
        },
        
        filterItem: function(itemElement) {
        	var categoryFilter = false;
        	var searchFilter = false;
        	if(AssabDownloads.isotopeFilters['categories'].length > 0) {
        		var itemClasses = $(itemElement).attr('class').split(/\s+/);
        		$.each(itemClasses, function(index, item){
					if($.inArray(item, AssabDownloads.isotopeFilters['categories']) !== -1) {
						categoryFilter = true;
					}
				});        		
        	} else {
        		categoryFilter = true;
        	}
        	if(AssabDownloads.qsRegex) {
        		searchFilter = $(itemElement).text().match(AssabDownloads.qsRegex);
        		if(!searchFilter) {
        			$(itemElement).addClass('not-in-search');
        		} else {
        			$(itemElement).removeClass('not-in-search');
        		}
        	} else {
        		searchFilter = true;
        	}
        	
        	return (categoryFilter && searchFilter) ? true : false;
        },
                
        isotope: function() {
        	AssabDownloads.isotopeContainer.isotope({
				itemSelector: '.item',
				layoutMode: 'fitRows',
				filter: function() {
					return AssabDownloads.filterItem(this);
				},
				getSortData: {
					number: '[data-sort] parseInt'
				},
				sortBy: 'number'
			});
        },
        
        renderTitle: function() {
        	$('#download-categories > div').html('');
        	var selectedFilters = $('.categories button.is-checked, .categories button.active');
        	if(selectedFilters.length > 0) {
        		$('#download-categories h3').show();
        	} else {
        		$('#download-categories h3').hide();
        	}
        	$(selectedFilters).each(function(key, value) {
        		
        		var mainCategoryName = $(value).find('p strong').html();
        		
        		$('#download-categories > div').append(
        			$('<div/>').addClass('filter-' + key).append(
        				$('<h4/>')
        					.html(mainCategoryName)
        					.data('filter-category', $(this).data('filter-category'))
        					.append($('<i/>').addClass('icon icon-cross'))
        			)
        		);
        		
        		// has sub-categories?
        		var subCategories = $(this).parent().parent().find('.sub-categories a.is-checked');
        		var catKey = key;
        		if(subCategories) {
        			$.each(subCategories, function(key, value) {
        				if($(value).text().trim() !== mainCategoryName.trim()) {
        					$('#download-categories > div > div.filter-' + catKey).append(
	        					$('<span/>')
	        						.append($(value).text())
	        						.data('filter-category', $(this).data('filter-category'))
	        						.append($('<i/>').addClass('icon icon-cross'))
	        				);
        				}
        			});
        		}
        		
        		// bind unselect events
        		$('#download-categories > div span > i').unbind();
        		$('#download-categories > div span > i').on('click', function() {
        			var filterId = $(this).parent().data('filter-category');
        			$('.categories a[data-filter-category=' + filterId + ']').trigger('delete-filter');
        		});
        		$('#download-categories > div h4 > i').unbind();
        		$('#download-categories > div h4 > i').on('click', function() {
        			var filterId = $(this).parent().data('filter-category');
        			$('.categories button[data-filter-category=' + filterId + ']').parent().find('i.top').trigger('click');
        		});
        	});
        },
        
        destroy: function() {
        	AssabDownloads.isotopeContainer.isotope('destroy');
        	$('#downloadsearchinput').typeahead('destroy');
        }
    };

    $(window).load(function() {
    	// Isotope
    	if($("#downloadsearchinput").length > 0) {
    		AssabDownloads.initWhenDomIsReady();
    	}
    });
})(jQuery);